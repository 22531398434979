<template>
  <div class="part2">
    <div style="width: 100%;display:flex;justify-content:center">
      <div class="part5">
        <div class="middleTitle">云平台</div>
        <div class="more" @click="seeMore"> >>查看详情</div>
        <div class="part5InsideBackground">
          <div class="pic1">
            <div  class="one" @click="part5SwitchCountAdd(1)">
              <span v-show="!part5SwitchCardVisual">报名</span>
              <span v-show="part5SwitchCardVisual">提交</span>
              <i v-show="part5SwitchCount===1"></i>
            </div>
            <div class="two" @click="part5SwitchCountAdd(2)">
              <span>研讨</span>
              <i v-show="part5SwitchCount===2"></i>
            </div>
            <div class="three" @click="part5SwitchCountAdd(3)">
              <span>合同</span>
              <i v-show="part5SwitchCount===3"></i>
            </div>
            <div class="four" @click="part5SwitchCountAdd(4)">
              <span>研发</span>
              <i v-show="part5SwitchCount===4"></i>
            </div>
            <div class="five" @click="part5SwitchCountAdd(5)">
              <span>交付</span>
              <i v-show="part5SwitchCount===5"></i>
            </div>
            <div class="six" @click="part5SwitchCountAdd(6)">
              <span>结果</span>
              <i v-show="part5SwitchCount===6"></i>
            </div>
            <div class="geo_2-1"></div>
            <div class="geo_2-2"></div>
            <div class="geo_2-3"></div>
            <div class="geo_2-4"></div>
            <p>简化服务流程、服务创新，提供落地体验</p>
            <button class="demand" :style="part5SwitchDemand" @click="part5Switch(0)">需求方</button>
            <button class="resolver" :style="part5SwitchResolver" @click="part5Switch(1)">解决方</button>
          </div>
          <div class="part5Information">
            <div>
              <!--需求方-->
              <div class="pic2" v-show="part5SwitchCardVisual">
                <div class="part5title">
                  <div class="up" v-show="part5SwitchCount === 1">
                    提交需求
                  </div>
                  <div class="up" v-show="part5SwitchCount === 2">
                    协同研讨
                  </div>
                  <div class="up" v-show="part5SwitchCount === 3">
                    合同签订
                  </div>
                  <div class="up" v-show="part5SwitchCount === 4">
                    协同研发
                  </div>
                  <div class="up" v-show="part5SwitchCount === 5">
                    项目交付
                  </div>
                  <div class="up" v-show="part5SwitchCount === 6">
                    项目结束
                  </div>
                  <button class="butL" @click="part5SwitchCountMinus"><i class="fa fa-angle-left" style="font-size:.24rem; color:white;"></i></button>
                  <button class="butR" @click="part5SwitchCountAdd(0)"><i class="fa fa-angle-right" style="font-size:.24rem; color:white;"></i></button>
                </div>
                <div>
                  <div class="middle" v-show="part5SwitchCount === 1">
                    注册登陆后提交需求，填写公司名称联系方式选择需求类型，后台审核后为您选择合适的解决方式，一站式落地。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 2">
                    需求方、设计方、加工方三方会谈，保障需求完整性的同时探讨最佳解决方案。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 3">
                    各方签署具有法律效益的合同文件，确保报价合理，时间节点准确，服务项目完整。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 4">
                    依据合同严格分工，提供7*24的全程跟踪，以最快速、最高效、最优质的解决方案为需求方提供完美的服务体验。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 5">
                    对项目交付款项支付及后续修改等过程进行全程跟踪，在确保各方利益的同时减少过程中的复杂手续。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 6">
                    在项目完成之后进行后期追踪，以及对过程中产生的问题建议进行收集，力图让平台服务更加完善。
                  </div>
                </div>
                <button class="down" @click="this.$router.push('/RequestDetail')" v-show="isLogin === '1' && this.role === 'enterprise' ">发布需求</button>
              </div>
              <!--解决方-->
              <div class="pic2" v-show="!part5SwitchCardVisual">
                <div class="part5title">
                  <div class="up" v-show="part5SwitchCount === 1">
                    报名参加
                  </div>
                  <div class="up" v-show="part5SwitchCount === 2">
                    协同研讨
                  </div>
                  <div class="up" v-show="part5SwitchCount === 3">
                    合同签订
                  </div>
                  <div class="up" v-show="part5SwitchCount === 4">
                    协同研发
                  </div>
                  <div class="up" v-show="part5SwitchCount === 5">
                    项目交付
                  </div>
                  <div class="up" v-show="part5SwitchCount === 6">
                    项目结束
                  </div>
                  <button class="butL" @click="part5SwitchCountMinus"><i class="fa fa-angle-left" style="font-size:.24rem; color:white;"></i></button>
                  <button class="butR" @click="part5SwitchCountAdd(0)"><i class="fa fa-angle-right" style="font-size:.24rem; color:white;"></i></button>
                </div>
                <div>
                  <div class="middle" v-show="part5SwitchCount === 1">
                    提交完整的资格手续，我们将7*24小时进行审核，筛选合适合理合格的方案及生态链企业参与项目制作。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 2">
                    需求方、设计方、加工方三方会谈，保障需求完整性的同时探讨最佳解决方案。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 3">
                    各方签署具有法律效益的合同文件，确保报价合理，时间节点准确，服务项目完整。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 4">
                    依据合同严格分工，提供7*24的全程跟踪，以最快速、最高效、最优质的解决方案为需求方提供完美的服务体验。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 5">
                    对项目交付款项支付及后续修改等过程进行全程跟踪，在确保各方利益的同时减少过程中的复杂手续。
                  </div>
                  <div class="middle" v-show="part5SwitchCount === 6">
                    在项目完成之后进行后期追踪，以及对过程中产生的问题及建议进行收集整理，力图让平台服务更加完善。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pic3" v-show="part5SwitchCount === 1"></div>
          <div class="pic3 pic3_2" v-show="part5SwitchCount === 2"></div>
          <div class="pic3 pic3_3" v-show="part5SwitchCount === 3"></div>
          <div class="pic3 pic3_4" v-show="part5SwitchCount === 4"></div>
          <div class="pic3 pic3_5" v-show="part5SwitchCount === 5"></div>
          <div class="pic3 pic3_6" v-show="part5SwitchCount === 6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Part2",
  data(){
    return{
      part5SwitchCardVisual: true,
      part5SwitchCount:1,
      part5SwitchDemand:'',
      part5SwitchResolver:'',
    }
  },
  methods:{
    seeMore(){
      this.$router.push('/Platform');
    },

    part5Switch(index) {
      this.part5SwitchCount = 1;
      if(index === 0){
        this.part5SwitchDemand = "background-color: #ffffff; color: #ec414d;"
        this.part5SwitchResolver = "background-color: #ec414d; color: #ffffff;border:1px solid #ffffff"
        this.part5SwitchCardVisual = true;
      }else if(index === 1){
        this.part5SwitchDemand = "background-color: #ec414d; color: #ffffff;border:1px solid #ffffff"
        this.part5SwitchResolver = "background-color: #ffffff; color: #ec414d;"
        this.part5SwitchCardVisual = false;
      }
    },

    part5SwitchCountAdd(index){
      if (index === 0){
        this.part5SwitchCount++;
        if(this.part5SwitchCount >= 7){
          this.part5SwitchCount = 1;
        }
      }else {
        this.part5SwitchCount = index;
      }
    },

    part5SwitchCountMinus(){
      this.part5SwitchCount--;
      if(this.part5SwitchCount <= 0){
        this.part5SwitchCount = 6;
      }
    },
  }
}
</script>

<style scoped>

.part2{
  padding-top: .72rem;
  /*padding-left: .35rem;*/
}

.part2 .middleTitle{
  float: left;
  color: rgb(235,66,77);
  font-size: .36rem;
  font-weight: 500;
  margin-left: .92rem;
  margin-bottom: .3rem;
}

.part2  .more{
  float: right;
  color: black;
  font-size: .35rem;
  font-weight: 400;
  cursor: pointer;
  margin-right: 1.32rem;
  z-index: 9;
}

.part2 .more:hover{
  color: #ec414d;
}


.part5{
  position: relative;
  width: 90%;
  height: 9.78rem;
}

.part5 .pic1{
  position: absolute;
  left: 2.73rem;
  top: .98rem;
  width: 10rem;
  height: 8rem;
  background-color: #ec414d;

}

.part5 .pic1 span{
  cursor: pointer;
}

.part5 .pic1 .one{
  position: absolute;
  left: 1.82rem;
  top: 3.63rem;
  width: 1.72rem;
  height: .28rem;
  margin-bottom: .45rem;
  font-size: .24rem;
  color: #ffffff;
  line-height: .28rem;
}

.part5 .pic1 .two{
  position: absolute;
  left: 1.82rem;
  top: 4.16rem;
  width: 1.72rem;
  height: .28rem;
  /*background-color: blue;*/
  /*z-index: 1;*/
  font-size: .24rem;
  color: #ffffff;
  line-height: .28rem;

}

.part5 .pic1 .three{
  position: absolute;
  left: 1.82rem;
  top: 4.69rem;
  width: 1.72rem;
  height: .28rem;
  /*background-color: blue;*/
  /*z-index: 1;*/
  font-size: .24rem;
  color: #ffffff;
  line-height: .28rem;
}

.part5 .pic1 .four{
  position: absolute;
  left: 1.82rem;
  top: 5.22rem;
  width: 1.72rem;
  height: .28rem;
  /*background-color: blue;*/
  /*z-index: 1;*/
  font-size: .24rem;
  color: #ffffff;
  line-height: .28rem;
}

.part5 .pic1 .five{
  position: absolute;
  left: 1.82rem;
  top: 5.75rem;
  width: 1.72rem;
  height: .28rem;
  /*background-color: blue;*/
  /*z-index: 1;*/
  font-size: .24rem;
  color: #ffffff;
  line-height: .28rem;
  z-index: 20;
}

.part5 .pic1 .six{
  position: absolute;
  left: 1.82rem;
  top: 6.3rem;
  width: 1.72rem;
  height: .28rem;
  /*background-color: blue;*/
  font-size: .24rem;
  color: #ffffff;
  line-height: .28rem;
  z-index: 20;
}

.part5 .pic1 .one,.two,.three,.four,.five,.six{
  float: left;
}

.part5 .pic1 i{
  display: block;
  float: right;
  width: .97rem;
  height: .04rem;
  margin-top: .12rem;
  background-color: #ffffff;


}

.part5 p{
  margin-top: 1.5rem;
  margin-left: 1.82rem;
  font-size: .3rem;
  font-weight: 700;
  color: #ffffff;
}

.part5 .demand{
  height: .5rem;
  width: 1.05rem;
  font-size: .16rem;
  margin-left: 3.18rem;
  margin-right: .15rem;
  margin-top: .42rem;
  line-height: .5rem;
  text-align: center;
  background-color: #ffffff;
  /*border: .01rem solid transparent;*/
  border-radius: .03rem;
  cursor: pointer;
  color: #ec414d;
}



.part5 .resolver{
  height: .5rem;
  width: 1.05rem;
  font-size: .16rem;
  border-radius: .05rem;
  line-height: .5rem;
  text-align: center;
  margin-top: .42rem;
  background-color: #ffffff;
  /*border: .01rem solid transparent;*/
  cursor: pointer;
  /*background-image: linear-gradient(115deg,transparent 50%,#212121 50%);*/
  /*background-repeat: no-repeat;*/
  /*background-size: 30%;*/
  /*background-position: right bottom;*/
  color: #212121;
}

.part5 .pic2{
  position: absolute;
  width: 4.66rem;
  height: 4.32rem;
  top: 4rem;
  left: 5.92rem;
  border-radius: .1rem;
  background-color: #ffffff;
  z-index: 1;
}

.part5 .pic2 .down:hover{
  background-size: 300%;
}

.part5 .pic2 .up{
  color: black;
  width:  5.54rem;
  height: .38rem;
  font-size: .36rem;
  font-weight: 500;
  margin-left: .4rem;
  margin-top:.5rem;
  margin-bottom: .74rem;
}

.part5 .pic2 .part5title button{
  border-radius: 50%;
  position: absolute;
  right: .6rem;
  top: .7rem;
  width: .32rem;
  height: .32rem;

}

.part5 .pic2 .part5title button:hover{
  background-color: rgb(235, 66, 77);
  /*border-radius: 50%;*/
  /*position: absolute;*/
  /*right: .6rem;*/
  /*top: .7rem;*/
  /*width: .32rem;*/
  /*height: .32rem;*/

}

.pic2 .down:hover{
  background-size: 300%;
  transition: all 1s;
  color: #ffffff;
}


.part5 .pic2 .part5title .butL{
  margin-right: .45rem;
}

.part5 .pic1 .geo_2-1{
  position: absolute;
  width: 2.2rem;
  height: 2.2rem;
  left: .75rem;
  top: 1.44rem;
  background: url("../../images/geo_1_1.png") no-repeat;
}


.part5 .pic1 .geo_2-2{
  position: absolute;
  width: 2.2rem;
  height: 2.2rem;
  left: 6.8rem;
  top: .82rem;
  background: url("../../images/geo_1_2.png") no-repeat;
}

.part5 .pic1 .geo_2-3{
  position: absolute;
  width: 2.7rem;
  height: 2.7rem;
  right:1.73rem;
  bottom:.18rem;
  margin-left: .42rem;
  background: url("../../images/geo_1_3.png") no-repeat;

}

.part5 .pic1 .geo_2-4{
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  left:.58rem;
  bottom:1.21rem;
  margin-left: .42rem;
  background: url("../../images/geo_1_4.png") no-repeat;

}

.part5 .pic2 .middle{
  color: black;
  width: 4rem;
  height: .9rem;
  margin-left: .4rem;
  font-size: .21rem;
  font-weight: 300;
  margin-bottom: .8rem;

}

.part5 .pic2 .down{
  width: 1.38rem;
  height: .5rem;
  color: #ffffff;
  margin-left: .5rem;
  font-size: .16rem;
  background-color: #ec414d;
  /*border: .01rem solid transparent;*/
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all linear .7s;
}

.part5 .pic3{
  position: absolute;
  width: 5.6rem;
  height: 5.6rem;
  top: 2.3rem;
  right: 1.6rem;
  background-image: url("../../images/part5_fuwu_1.jpg");
  background-size: 100% 100%;
}

.part5 .pic3_2{
  background-image: url("../../images/part5_fuwu_2.jpg");
  background-size: 100% 100%;
}

.part5 .pic3_3{
  background-image: url("../../images/part5_fuwu_3.jpg");
  background-size: 100% 100%;
}

.part5 .pic3_4{
  background-image: url("../../images/part5_fuwu_4.jpg");
  background-size: 100% 100%;
}

.part5 .pic3_5{
  background-image: url("../../images/part5_fuwu_5.jpg");
  background-size: 100% 100%;
}

.part5 .pic3_6{
  background-image: url("../../images/part5_fuwu_6.jpg");
  background-size: 100% 100%;
}

.part5 .pic1 .geo_2-1{
  animation-name: run;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

.part5 .pic1 .geo_2-2{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.part5 .pic1 .geo_2-3{
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.part5 .pic1 .geo_2-4{
  animation-name: rotate;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/*动画部分*/
@keyframes rotate {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes  run{
  0%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,.8rem);
  }
}
</style>